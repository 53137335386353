class FoodService {
    constructor() {
        this.BASE_URL = 'https://demo.felloweschristopher.com/api';
        this.categories = [
            'All',
            'Snacks',
            'Beverages',
            'Dairy',
            'Cereals',
            'Condiments',
            'Canned Goods',
            'Frozen Foods',
            'Fresh Produce'
        ];
    }

    async listFoods() {
        const response = await fetch(`${this.BASE_URL}/foods`);
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to fetch foods');
        }

        const data = await response.json();
        return data;
    }

    // Store food item
    async storeFood(foodData) {
        const response = await fetch(`${this.BASE_URL}/food`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(foodData)
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message);
        }

        return response.json();
    }

    // Get food by ID
    async getFood(id) {
        const response = await fetch(`${this.BASE_URL}/food/${id}`);
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message);
        }

        return response.json();
    }

    // Delete food by ID
    async deleteFood(id) {
        const response = await fetch(`${this.BASE_URL}/food/${id}`, {
            method: 'DELETE'
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message);
        }

        return true;
    }

    // Get all categories
    getCategories() {
        return this.categories;
    }

    getGenericDescription() {
        return `This premium food product meets the highest standards of quality and safety in the industry. Carefully sourced and meticulously processed under strict quality control measures, this product represents our commitment to excellence in food safety and consumer satisfaction. Each batch undergoes rigorous testing and inspection procedures to ensure consistency in quality and taste.

Our comprehensive quality assurance program includes multiple checkpoints throughout the production process, from ingredient selection to final packaging. We maintain full traceability of all ingredients and follow HACCP (Hazard Analysis Critical Control Point) principles to guarantee food safety. The product is manufactured in facilities certified by international food safety standards organizations.

The manufacturing facility adheres to Good Manufacturing Practices (GMP) and is regularly audited by independent third-party organizations. Our quality control laboratory conducts thorough microbiological and chemical analyses to verify product safety and quality. We maintain detailed documentation of all quality control measures and test results, ensuring complete transparency in our food safety protocols.

All ingredients used in this product are carefully selected from approved suppliers who share our commitment to quality and food safety. Regular supplier audits and ingredient testing help maintain our high standards throughout the supply chain.`;
    }
}

const foodService = new FoodService();
export default foodService;