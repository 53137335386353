import { useState, useEffect } from 'react';
import './App.css';
import FoodService from './services/FoodService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
    const [products, setProducts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [cartCount, setCartCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [newProduct, setNewProduct] = useState({
        name: '',
        brand: '',
        source: '',
        safetyRating: '',
    });

    useEffect(() => {
        loadProducts();
    }, []);

    const loadProducts = async () => {
        try {
            const productList = await FoodService.listFoods();
            setProducts(productList);
        } catch (error) {
            toast.error(error.message);
        }
    };

    const filteredProducts = products.filter(product =>
        selectedCategory === 'All' || product.category === selectedCategory
    );

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await FoodService.storeFood(newProduct);
            setNewProduct({
                name: '',
                brand: '',
                source: '',
                safetyRating: '',
            });
            setIsModalOpen(false);
            loadProducts();
        } catch (error) {
            toast.error(error.message);
        }
    };


    const handleAddToCart = (productId) => {
        setCartCount(prev => prev + 1);
    };

    const handleProductClick = (product) => {
        setSelectedProduct(product);
        setIsDetailModalOpen(true);
    };

    const handleDeleteProduct = async (productId) => {
        try {
            await FoodService.deleteFood(productId);
            loadProducts();
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <div className="App">
            <header className="app-header">
                <div className="header-content">
                    <h1>FoodMart</h1>
                    <div className="cart-icon">
                        🛒 <span className="cart-count">{cartCount}</span>
                    </div>
                </div>
            </header>

            <ToastContainer position="top-right" autoClose={3000} />

            <div className="content-container">
                <div className="category-filter">
                    {FoodService.getCategories().map(category => (
                        <button
                            key={category}
                            className={`category-button ${selectedCategory === category ? 'active' : ''}`}
                            onClick={() => setSelectedCategory(category)}
                        >
                            {category}
                        </button>
                    ))}
                </div>

                <div className="products-grid">
                    {filteredProducts.map(product => (
                        <div
                            key={product.id}
                            className="product-card"
                        >
                            <div
                                className="product-image"
                                style={{
                                    background: product.bgColor,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                onClick={() => handleProductClick(product)}
                            >
                                <span className="product-title">{product.name}</span>
                            </div>
                            <div className="product-content">
                                <div className="product-brand">{product.brand}</div>
                                <h3 className="product-name">{product.name}</h3>
                                <div className="safety-rating">
                                    Safety Rating: {product.safetyRating}
                                </div>
                                <div className="product-source">📍 {product.source}</div>

                                <div className="button-group">
                                    <button
                                        className="add-to-cart-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToCart(product.id);
                                        }}
                                    >
                                        Add to Cart
                                    </button>

                                    <button
                                        className="delete-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteProduct(product.id);
                                        }}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <button
                className="fab-button"
                onClick={() => setIsModalOpen(true)}
            >
                +
            </button>

            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Add New Product</h2>
                        <form onSubmit={handleSubmit}>
                            <input
                                value={newProduct.name}
                                onChange={(e) => setNewProduct({...newProduct, name: e.target.value})}
                                placeholder="Product Name"
                                className="form-input"
                                required
                            />
                            <input
                                value={newProduct.brand}
                                onChange={(e) => setNewProduct({...newProduct, brand: e.target.value})}
                                placeholder="Brand"
                                className="form-input"
                                required
                            />
                            <input
                                value={newProduct.source}
                                onChange={(e) => setNewProduct({...newProduct, source: e.target.value})}
                                placeholder="Source"
                                className="form-input"
                                required
                            />
                            <input
                                value={newProduct.safetyRating}
                                onChange={(e) => setNewProduct({...newProduct, safetyRating: e.target.value})}
                                placeholder="Safety Rating"
                                className="form-input"
                                required
                            />

                            <select
                                value={newProduct.category}
                                onChange={(e) => setNewProduct({...newProduct, category: e.target.value})}
                                className="form-input"
                                required
                            >
                                {FoodService.getCategories().filter(cat => cat !== 'All').map(cat => (
                                    <option key={cat} value={cat}>{cat}</option>
                                ))}
                            </select>

                            <div className="modal-buttons">
                                <button type="button" onClick={() => setIsModalOpen(false)} className="cancel-button">
                                    Cancel
                                </button>
                                <button type="submit" className="submit-button">
                                    Add Product
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Product Detail Modal */}
            {isDetailModalOpen && selectedProduct && (
                <div className="modal-overlay">
                    <div className="modal-content detail-modal">
                        <div className="detail-header">
                            <h2>{selectedProduct.name}</h2>
                            <button
                                className="close-button"
                                onClick={() => setIsDetailModalOpen(false)}
                            >
                                ×
                            </button>
                        </div>

                        <div
                            className="detail-image"
                            style={{
                                background: selectedProduct.bgColor,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <span className="detail-title">{selectedProduct.name}</span>
                        </div>

                        <div className="detail-content">
                            <div className="detail-section">
                                <h3>Product Information</h3>
                                <p><strong>Brand:</strong> {selectedProduct.brand}</p>
                                <p><strong>Category:</strong> {selectedProduct.category}</p>
                                <p><strong>Source:</strong> {selectedProduct.source}</p>
                                <p><strong>Safety Rating:</strong> {selectedProduct.safetyRating}</p>
                            </div>

                            <div className="detail-section">
                                <h3>Product Description</h3>
                                <p>{FoodService.getGenericDescription()}</p>
                            </div>

                            <div className="detail-section">
                                <h3>Safety Information</h3>
                                <ul>
                                    <li>FDA Approved</li>
                                    <li>Quality Tested</li>
                                    <li>Meets International Standards</li>
                                    <li>Regular Safety Audits</li>
                                </ul>
                            </div>

                            <div className="detail-section">
                                <h3>Storage Instructions</h3>
                                <ul>
                                    <li>Store in a cool, dry place</li>
                                    <li>Keep away from direct sunlight</li>
                                    <li>Check expiration date before use</li>
                                    <li>Follow package instructions</li>
                                </ul>
                            </div>

                            <button
                                className="add-to-cart-button detail-button"
                                onClick={() => {
                                    handleAddToCart(selectedProduct.id);
                                    setIsDetailModalOpen(false);
                                }}
                            >
                                Add to Cart
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default App;